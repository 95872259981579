import { useForm, ValidationError } from "@formspree/react";
import useBoolean from "../hooks/useBoolean";
import Button from "./UI/Button";
import Input from "./UI/Input";
import Switch from "./UI/Switch";

const ContactForm = ({ file }: { file: any }) => {
  const [state, handleSubmit] = useForm("xleqrzko");
  const [isArgee, setAgree] = useBoolean();

  const onSubmit = (event: any) => {
    if (!isArgee) {
      event.preventDefault();
      return;
    };

    // @ts-expect-error
    document.getElementById('hidden-report-url-input').value = window.location.href.split('?')[0];

    // @ts-expect-error
    window.dataLayer = window.dataLayer || [];
    // @ts-expect-error
    window.dataLayer.push({
      'event' : 'form_submit'
    });

    console.log("dataLayer debug");

    handleSubmit(event);

    window.open(`https://ensembleai.io${file}`, "_blank");
  }

  return (
    <div>
      <form className="text-center" onSubmit={onSubmit}>

        <Input
            type="text"
            inputProps={{ name: "firstName", required: true }}
            placeholder="First Name"
            label="First Name"
            className="!w-full mb-4"
            isRequired
        />
        <ValidationError
            prefix="Your First Name"
            field="firstName"
            errors={state.errors}
        />

        <input type="text" name="URL" id="hidden-report-url-input" style={{display: 'none'}} hidden />

        <Input
            type="text"
            inputProps={{ name: "lastName", required: true }}
            placeholder="Last Name"
            label="Last Name"
            className="!w-full mb-4"
            isRequired
        />
        <ValidationError
            prefix="Your Last Name"
            field="lastName"
            errors={state.errors}
        />

        <Input
            type="company"
            placeholder="Your Company Name"
            className="!w-full mb-4"
            inputProps={{ name: "company", required: false }}
            label="Company Name"
            isRequired
        />
        <ValidationError
          prefix="Company Name"
          field="name"
          errors={state.errors}
        />

        <Input
            type="email"
            placeholder="Type your company email"
            className="!w-full mb-4"
            inputProps={{ name: "email", required: true }}
            label="Company Email"
            isRequired
        />
        <ValidationError
          prefix="Company email"
          field="email"
          errors={state.errors}
        />

{/*
        <label className="text-sm text-left text-grey-400 block mb-2">
          Job Function
          <span className="text-error"> *</span>
        </label>
        <div className="chevron-down-right mb-4">
          <select
            defaultValue=""
            name="Job function"
            required
            className="
                relative px-6 h-14 rounded-[50px] w-full text-base
                border border-grey-100 bg-white text-black-light invalid:text-[#c9c9dd]
                focus:outline-none focus:border-black-light appearance-none chevron-down-right
            "
          >
            <option className="text-black-light" value="" disabled>Select your job function</option>
            <option className="text-black-light" value="1">General</option>
            <option className="text-black-light" value="2">Arrange A Trial</option>
            <option className="text-black-light" value="3">Book A Free Whiteboarding Session</option>
            <option className="text-black-light" value="4">Content Feedback</option>
            <option className="text-black-light" value="5">Partnerships</option>
          </select>
        </div>
        <ValidationError prefix="Help" field="Job function" errors={state.errors} />

        <Input
            type="text"
            inputProps={{ name: "country", required: true }}
            placeholder="Country"
            label="Country"
            className="!w-full mb-4"
            isRequired
        />
        <ValidationError
            prefix="Country"
            field="country"
            errors={state.errors}
        />
*/}
        {/*}<label className="text-sm text-left text-grey-400 block mb-2">
          How well do you currently know Kafka?
          <span className="text-error"> *</span>
        </label>
        <div className="chevron-down-right mb-4">
          <select
            defaultValue=""
            name="How well know"
            required
            className="
                relative px-6 h-14 rounded-[50px] w-full text-base
                border border-grey-100 bg-white text-black-light invalid:text-[#c9c9dd]
                focus:outline-none focus:border-black-light appearance-none chevron-down-right
            "
          >
            <option className="text-black-light" value="" disabled>Select</option>
            <option className="text-black-light" value="1">General</option>
            <option className="text-black-light" value="2">Arrange A Trial</option>
            <option className="text-black-light" value="3">Book A Free Whiteboarding Session</option>
            <option className="text-black-light" value="4">Content Feedback</option>
            <option className="text-black-light" value="5">Partnerships</option>
          </select>
        </div>
        <ValidationError prefix="Help" field="How well know" errors={state.errors} />
*/}
        <div className="mb-4 flex lg:items-center lg:flex-row gap-8 lg:gap-4 flex-col lg:justify-between">
          <div className="flex gap-4">
            <div className="mt-1">
                <Switch
                    isEnabled={isArgee}
                    onChange={setAgree.toggle}
                    color="red"
                    size="small"
                />
            </div>
            <span className="text-sm text-left">
              By clicking "Download Now" you agree to receive occassional marketing emails from Ensemble.   
            </span>
          </div>
        </div>

        <Button
            actionType="submit"
            color="red"
            label="Download Now"
            disabled={!isArgee}
            className={`mt-6 mx-auto ${isArgee ? '' : '!text-[#000]'}`}
            labelClassName={`reports-form-button`}
        />
        
      </form>
      {state.succeeded ? (
        <p className="text-xl text-center mb-4 mt-4">
          The download should start now.
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContactForm;
