import React from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import classNames from "classnames";

interface ISwitchProps {
  isEnabled: boolean;
  onChange: () => void;
  name?: string;
  isRequired?: string;
  color?: "red" | "white-light";
  size?: "big" | "small";
}

const Switch: React.FC<ISwitchProps> = ({
  isEnabled,
  onChange,
  name,
  isRequired,
  color = "white-light",
  size = "big",
}) => {
  return (
    <HeadlessSwitch
      checked={isEnabled}
      onChange={onChange}
      className={classNames("relative inline-flex items-center rounded-full", {
        "bg-white bg-opacity-20": color === "white-light",
        "bg-gradient-to-r from-red-600 to-pink-600": color === "red" && isEnabled,
        "bg-grey-100": color === "red" && !isEnabled,
        "h-6 w-11": size === "small",
        "h-[35px] w-[60px]": size === "big",
      })}
    >
      <span
        className={classNames(
          "inline-block transform rounded-full bg-white transition shadow-md",
          {
            "translate-x-full": isEnabled,
            "w-5 h-5 ml-0.5": size === "small",
            "ml-[5px] h-[25px] w-[25px]": size === "big",
          }
        )}
      />
      <input
        className="hidden"
        type="checkbox"
        checked={isEnabled}
        name={name}
        required={!!isRequired}
      />
    </HeadlessSwitch>
  );
};

export default Switch;
